import type { FC } from 'react';
import PropTypes from 'prop-types';
import {FilledTextFieldProps, TextField} from "@mui/material";
import {SxProps} from "@mui/system";
import {alpha} from "@mui/material/styles";
import { SEDEO_PALETTE } from '../../theme/themeColors';

interface InputFieldProps extends Omit<FilledTextFieldProps, 'variant'> {
  InputProps?: Record<string, any>;
  sx?: SxProps;
  labelFontSize?: number;
  isLabelBold?: boolean;
  inputPaddingY?: number;
}

export const InputField: FC<InputFieldProps> = (props) => {
  const { InputProps, sx, labelFontSize, isLabelBold, inputPaddingY, ...other } = props;

  const inputPy = inputPaddingY ? inputPaddingY : 0.75;
  return (
    <TextField
      inputProps={{
        sx: {
          alignItems: 'center',
          display: 'flex',
          fontSize: 14,
          height: 'unset',
          lineHeight: 1.6,
          px: 1.5,
          py: inputPy,
          '&.MuiInputBase-inputAdornedStart': {
            pl: 0
          },
          '&:-webkit-autofill': {
            borderRadius: '0px',
            WebkitBoxShadow: '0 0 0 100px #fff inset',
          }
        }
      }}
      variant="filled"
      InputLabelProps={{
        shrink: true,
        sx: {
          color: 'text.primary',
          fontSize: labelFontSize??14,
          fontWeight: isLabelBold ? 'bold': undefined,
          mb: 0.5,
          position: 'relative',
          transform: 'none'
        }
      }}
      sx={{
        '& .MuiFilledInput-root': {
          backgroundColor: 'background.paper',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'neutral.300',
          borderRadius: 25,
          boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
          overflow: 'hidden',
          p: 0,
          transition: (theme) => theme.transitions.create([
            'border-color',
            'box-shadow'
          ]),
          '&:before': {
            borderBottom: 0
          },
          '&:hover': {
            backgroundColor: 'background.paper'
          },
          '&.Mui-focused': {
            backgroundColor: 'background.paper',
            boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
          },
          '&.Mui-disabled': {
            backgroundColor: SEDEO_PALETTE.disabledBackground,
            boxShadow: 'none',
            borderColor: alpha(SEDEO_PALETTE.lightBackground, 0.5)
          },
          '.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
            mt: 0,
            ml: 1.5
          }
        },
        ...sx
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...InputProps,
        disableUnderline: true
      }}
      {...other}
    />
  );
};

InputField.propTypes = {
  InputProps: PropTypes.object,
  sx: PropTypes.object
};
