import { CircularProgress } from "@mui/material";

export const LoadingAnimation = () => {
    return (
        <>
            {/* <Toolbar/> */}
            <div className="loading-container">
                <CircularProgress
                aria-label={"loader"}/>
            </div>
        </>
    )
}